<template>
        <ul>
            <li class="text">{{ $t('faq.tivimate.step2.firstSearch') }}</li>
            <li class="text">{{ $t('faq.tivimate.step2.useThisLink') }} 
                <a class="link" :href="tiviLink" target="_blank">{{ tiviLink }}</a>
            </li>
            <li class="text">{{ $t('faq.tivimate.step2.download') }}</li>
            <li class="text">{{ $t('faq.tivimate.step2.open') }}</li>
        </ul>
</template>
<script>
export default {
    data() {
        return {
            tiviLink: 'https://troypoint.com/tivi'
        }
    },
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
